@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  
.gradient {
  --size: 200px;
  --speed: 40s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 1000));
  background-image: linear-gradient(hsla(241, 82%, 57%, 0.85), hsl(192, 82%, 57%));
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  align-content: center;
}
  
  @media (min-width: 720px) {
    .gradient {
      --size: 425px;
    }
  }
  
  /* This is just to transition when you change the viewport size. */
* {
    transition: all 0.25s ease-out;
}
  