@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.navbar ul li {
		@apply relative tracking-[1px];
	}
	.navbar ul li::after {
		@apply content-[''] bg-[#007ced] h-[2px] w-[0%] left-0 -bottom-[-28px] rounded-full absolute duration-700;
	}
	.navbar ul li:hover::after {
		@apply w-[100%];
	}
}

* {
	@apply transition-colors duration-500;
  }