 
  
  /* Card Style */
  .bg-white {
    background-color: #ffffff;
  }
  .dark\:bg-gray-800 {
    background-color: #1f1f1f;
  }
  .w-full {
    width: 100%;
  } 
  .mt-20 {
    margin-top: 5rem;
  }
  .pb-10 {
    padding-bottom: 2.5rem;
  }
  .px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  
  .shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .dark\:shadow-xl {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
  .shadow-black {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
  
  /* Vertical line styling */
  .vertical-line {
    width: 4px; /* Line thickness */
    background-color: #4A90E2; /* Line color (blue, change as needed) */
    height: 100%; /* Full height of the container */
    margin-right: 20px; /* Spacing between the line and content */
    border-radius: 5px; /* Optional: rounded edges for the line */
  }
  
  /* Content section inside the card */
  .content-section {
    flex: 1; /* Ensure content takes available space */
  }
  
  /* Header Styling */
  .text-center {
    text-align: center;
  }
  .text-3xl {
    font-size: 2rem;
  }
  .font-semibold {
    font-weight: 600;
  }
  .text-primaryColor {
    color: #4A90E2; /* Primary Color */
  }
  .border-b-4 {
    border-bottom-width: 4px;
  }
  .border-primaryColor {
    border-color: #4A90E2;
  }
  .pb-5 {
    padding-bottom: 1.25rem;
  }
  
  /* Education Entries Styling */
  .space-y-8 {
    margin-top: 2rem;
  }
  
  .education-entry h2 {
    font-size: 1.25rem;
    font-weight: 700;
    color: #4A90E2;
  }
  
  .education-entry p {
    font-size: 1rem;
    color: #444;
  }
  
  .text-gray-600 {
    color: #595959;
  }
  
  .text-primaryColor {
    color: #4A90E2;
  }
  
  .hover\:underline:hover {
    text-decoration: underline;
  }
  